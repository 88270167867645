<template>
  <div class="fleet">
    <div class="absolute top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="px-4 md:px-10 mx-auto w-full relative -mt-11">
      <HelpBar :noDate="true" />
      <div class="flex flex-wrap mt-4">
        <SlideUp>
          <ListTable
            :tableName="$t('user')"
            :headers="headers"
            :tableData="filterdUsers"
            :loading="$store.state.users.loading"
            createName="User-Detail"
          />
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from "@/components/ListTable.vue";
import SlideUp from "@/components/transitions/SlideUp.vue";
import userMixins from "@/mixins/userMixins";
import HelpBar from "../components/HelpBar.vue";
export default {
  name: "User",
  mixins: [userMixins],
  components: {
    HelpBar,
    ListTable,
    SlideUp
  },
  data() {
    return {
      headers: [
        {
          name: "#",
          key: "index",
          type: "hidden",
          linkInfo: { name: "User-Detail", routeKey: "id", varKey: "id" }
        },
        {
          name: "name",
          keys: [
            {
              key: "profile_image",
              type: "imageOrInitials",
              initialKey: "name"
            },
            { key: "name", type: "" }
          ],
          key: "name",
          type: ""
        },
        { name: "email", key: "email", type: "" },
        {
          name: "account type",
          key: "account_type",
          type: "has_icon",
          rider: "fas fa-biking",
          admin: "fas fa-user-astronaut",
          manager: "fas fa-user-secret",
          supplier: "fas fa-user-tie"
        },
        { name: "hubs", key: "hubs", type: "hubs", noSort: true },
        {
          name: "status",
          key: "status",
          type: "tag",
          active: "green",
          inactive: "red",
          filter: true
        }
        // {
        //   name: "details",
        //   key: "id",
        //   type: "viewLink",
        //   linkInfo: { name: "User-Detail", routeKey: "id", varKey: "id" }
        // }
      ]
    };
  },
  computed: {
    filterdUsers() {
      return this.$store.state.users.users.filter(user => {
        if (user.hubs.length == 0) return true;
        return user.hubs.find(hub =>
          this.$store.state.selectedHubs.includes(hub.id)
        );
      });
    }
  },
  mounted() {
    this.$store.dispatch("users/getAllUsers");
    this.$store.dispatch("hubs/getAllHubs", hubs => {
      this.$store.commit(
        "SET_HUBS",
        hubs.map(({ id }) => id)
      );
    });
  }
};
</script>
